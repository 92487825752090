import { useState } from "react";
import styles from "./EditOrganizationForm.module.css";
import UnividCard from "../../generic/UnividCard";
import UnividButton from "../../buttons/UnividButton";
import PopupModal from "../../popups/PopupModal";
import EmailBlocklistManager from "./EmailBlocklistManager";
import { FaInfoCircle } from "react-icons/fa";
import HtmlToolTip from "../../generic/HtmlToolTip";

const EditOrganizationEmailSettings = ({ organizationId = null }) => {
  const [isEmailSettingsShowing, setIsEmailSettingsShowing] = useState(false);

  return (
    <UnividCard customPadding="calc(1em + 10px) 1em 1em calc(1em + 10px)">
      <h2 style={{ fontSize: "20px", marginBottom: "2vh" }}>Email settings</h2>
      <div className={styles.SubQuestionContainer}>
        <div className={styles.SectionSubTitleContainer}>
          <div
            style={{
              display: "flex",
              marginBottom: "0.5em",
              alignItems: "center",
            }}
          >
            <div className={styles.SectionSubTitle}>
              <div className={styles.SectionSubTitleText}>
                Email domains to block
              </div>
            </div>
            <HtmlToolTip
              title={
                <div>
                  <p>
                    Block emails from signing up to any of your webinars. For
                    example competitors, or free email providers.
                  </p>
                </div>
              }
            >
              <div style={{ display: "flex" }}>
                <FaInfoCircle
                  color="rgb(40, 40, 40)"
                  style={{
                    marginLeft: "8px",
                    fontSize: "14px",
                  }}
                />
              </div>
            </HtmlToolTip>
          </div>
        </div>
        <div className={styles.DoubleButtonContainer}>
          <UnividButton
            padding="fat"
            onClick={() => {
              setIsEmailSettingsShowing(true);
            }}
          >
            Configure
          </UnividButton>
        </div>
      </div>

      {isEmailSettingsShowing && (
        <PopupModal
          handleCloseModal={() => {
            setIsEmailSettingsShowing(false);
          }}
          wantedWidth={"70vw"}
        >
          <EmailBlocklistManager organizationId={organizationId} />
        </PopupModal>
      )}
    </UnividCard>
  );
};

export default EditOrganizationEmailSettings;
