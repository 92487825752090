import { useParams } from "react-router-dom";

import { connect, useDispatch, useSelector } from "react-redux";

import styles from "./OrganizationSettings.module.css";
import getUserOrganizationPermissions from "../../utils/getUserOrganizationPermissions.js";
import AccessDeniedPage from "../statusPages/AccessDeniedPage.js";
import EditOrganizationForm from "./EditOrganizationForm.js";
import EditOrganizationEmailSettings from "./EditOrganizationEmailSettings.js";
import { updateOrganizationName } from "../../../redux/slices/organizationSlice.js";
import { VARIABLES } from "../../../strings/variables.js";
import axios from "axios";
import { useState, useEffect } from "react";

const OrganizationSettings = ({ organization }) => {
  const { organizationId } = useParams();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);

  const permissions = getUserOrganizationPermissions(
    currentUser,
    organizationId,
  );

  const isAdmin = permissions?.admin;
  const hasAccess = isAdmin || currentUser?.is_super_user;
  const hasCreateAccess = permissions?.room?.create;
  const currentUserIsAdmin = permissions?.admin;

  if (!hasAccess && !hasCreateAccess) {
    return <AccessDeniedPage />;
  }
  const [numberOfOrganizationMembers, setNumberOfOrganizationMembers] =
    useState(null);

  useEffect(() => {
    axios
      .get(`${VARIABLES.apiUrl}organization/${organizationId}/members`, {
        withCredentials: true,
      })
      .then((res) => {
        setNumberOfOrganizationMembers(res.data.members.length);
      })
      .catch((error) => console.error(error));
  }, []);

  const dispatchNewOrganizationName = ({ newOrgName }) =>
    dispatch(updateOrganizationName(newOrgName));

  return (
    <div className={styles.TemplatesWrapper}>
      <div
        style={{
          display: "flex",
          marginTop: "1em",
          marginLeft: "0.5em",
          marginBottom: "1em",
          alignItems: "flex-start",
          gap: "2vw",
          flexDirection: "column",
        }}
      >
        <EditOrganizationForm
          organizationId={organizationId}
          organizationNameInit={organization?.name}
          dispatchNewOrganizationName={dispatchNewOrganizationName}
          numberOfOrganizationMembers={numberOfOrganizationMembers}
          currentUserIsAdmin={currentUserIsAdmin}
        />
        <EditOrganizationEmailSettings organizationId={organizationId} />
      </div>

      <div className={styles.TemplateCardsContainer}></div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isAuthenticated: state.user.isAuthenticated,
  isAuthenticatedUnauth: state.user.isAuthenticatedUnauth,
  organization: state.organization,
});

export default connect(mapStateToProps)(OrganizationSettings);
