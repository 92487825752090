import { useEffect, useState } from "react";
import styles from "./EmailBlocklistManager.module.css";

import { FaTrash } from "react-icons/fa6";
import UnividButton from "../../buttons/UnividButton";
import axios from "axios";
import { VARIABLES } from "../../../strings/variables";

const EmailBlocklistManager = ({ organizationId }) => {
  console.log("organizationid", organizationId);
  const [customDomains, setCustomDomains] = useState([]);
  const [newDomain, setNewDomain] = useState("");

  const [bulkBlocked, setBulkBlocked] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleBulkBlocked = (enabled) => {
    axios
      .put(
        `${VARIABLES.apiUrl}organization/${organizationId}/blocklist/bulk-status`,
        { enabled },
        { withCredentials: true },
      )
      .then((response) => {
        console.log("Bulk block response", response);
        if (response.status === 200 && response.data.updated) {
          setBulkBlocked(enabled);
          setSuccessMessage(`Bulk block ${enabled ? "enabled" : "disabled"}`);
        } else {
          setSuccessMessage("Update failed on server");
        }
      })
      .catch((err) => {
        console.error("Failed to update bulk block setting", err);
        setSuccessMessage("Error updating bulk block");
      })
      .finally(() => {
        setTimeout(() => setSuccessMessage(""), 4000);
      });
  };

  const handleAddDomain = async () => {
    const entries = newDomain
      .split(",")
      .map((d) => d.trim())
      .filter((d) => d);

    if (entries.length === 0) return;

    try {
      const response = await axios.post(
        `${VARIABLES.apiUrl}organization/${organizationId}/blocklist`,
        { domains: entries },
        {
          withCredentials: true,
        },
      );

      const { added, skipped, invalid } = response.data;

      const addedDomains = entries.slice(0, added); // preserve order
      setCustomDomains((prev) => [...prev, ...addedDomains]);

      const messageParts = [];
      if (added > 0) messageParts.push(`${added} added`);
      if (skipped > 0) messageParts.push(`${skipped} skipped (duplicates)`);
      if (invalid > 0) messageParts.push(`${invalid} invalid`);

      setSuccessMessage(messageParts.join(", "));
      setNewDomain("");
      setTimeout(() => setSuccessMessage(""), 4000);
    } catch (error) {
      console.error("Failed to add domains:", error);
      setSuccessMessage("Failed to sync with server");
      setTimeout(() => setSuccessMessage(""), 4000);
    }
  };

  const handleRemoveDomain = async (index) => {
    const domainToRemove = customDomains[index];
    try {
      await axios.delete(
        `${VARIABLES.apiUrl}organization/${organizationId}/blocklist/${encodeURIComponent(domainToRemove)}`,
        { withCredentials: true },
      );
      setCustomDomains(customDomains.filter((_, i) => i !== index));
      setSuccessMessage("Deleted 1 domain");
      setTimeout(() => setSuccessMessage(""), 4000);
    } catch (err) {
      console.error("Failed to delete domain:", err);
      setSuccessMessage("Failed to sync with server");
      setTimeout(() => setSuccessMessage(""), 4000);
    }
  };

  useEffect(() => {
    axios
      .get(`${VARIABLES.apiUrl}organization/${organizationId}/blocklist`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.status === 200 && res.data.blockList) {
          setCustomDomains(res.data.blockList);
        }
      });

    axios
      .get(
        `${VARIABLES.apiUrl}organization/${organizationId}/blocklist/bulk-status`,
        {
          withCredentials: true,
        },
      )
      .then((res) => {
        if (res.status === 200 && res.data.enabled) {
          setBulkBlocked(res.data.enabled);
        }
      });
  }, []);

  return (
    <div className={styles.Container}>
      <h2 className={styles.Title}>Email domains to block</h2>

      <div className={styles.Card}>
        <div className={styles.CardContent}>
          <div className={styles.CheckboxRow}>
            <input
              type="checkbox"
              id="bulkBlock"
              checked={bulkBlocked}
              onChange={(e) => handleBulkBlocked(e.target.checked)}
            />
            <label htmlFor="bulkBlock">
              Block free email providers (3000+ domains)
            </label>
          </div>
          {bulkBlocked && (
            <a
              href="https://help.univid.io/en/articles/11037181-block-email-domains-from-signing-up"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.Link}
            >
              View full list
            </a>
          )}
        </div>
      </div>

      <div className={`${styles.Card} ${styles.StickyInputRow}`}>
        <div className={styles.CardContent}>
          <h3 className={styles.Subtitle}>Custom blocked domains</h3>
          <div className={styles.InputRow}>
            <input
              type="text"
              placeholder="example.com or comma separated domains"
              value={newDomain}
              onChange={(e) => setNewDomain(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleAddDomain()}
              className={styles.Input}
            />

            <UnividButton padding="fat" onClick={handleAddDomain}>
              {"Add"}
            </UnividButton>
          </div>
          {successMessage && <p>{successMessage}</p>}
        </div>
      </div>

      <div className={styles.Card}>
        <div className={styles.CardContent}>
          <ul className={styles.List}>
            {customDomains.map((domain, index) => (
              <li key={index} className={styles.ListItem}>
                <span>{domain}</span>
                <div className={styles.Actions}>
                  <button
                    onClick={() => handleRemoveDomain(index)}
                    className={styles.IconButton}
                  >
                    <FaTrash />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EmailBlocklistManager;
