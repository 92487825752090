import { useState, useEffect } from "react";
import RoundTextInput from "../../textinput/RoundTextInput";
import styles from "./EditOrganizationForm.module.css";
import UnividCard from "../../generic/UnividCard";
import UnividButton from "../../buttons/UnividButton";
import axios from "axios";
import { VARIABLES } from "../../../strings/variables";
import AlertMessageBox from "../../alertmessage/AlertMessageBox";
import RemoveOrganizationModal from "../members/remove/RemoveOrganizationModal";
import { Menu, MenuItem } from "@mui/material";
import ExpandMenuButton from "../../buttons/ExpandMenuButton";
import { FaCircleXmark } from "react-icons/fa6";
import HtmlToolTip from "../../generic/HtmlToolTip";

const EditOrganizationForm = ({
  organizationId = null,
  organizationNameInit = null,
  dispatchNewOrganizationName,
  numberOfOrganizationMembers,
  currentUserIsAdmin,
}) => {
  const [organizationName, setOrganizationName] =
    useState(organizationNameInit);
  const [hasMadeChanges, setHasMadeChanges] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showDeleteOrganizationModal, setShowDeleteOrganizationModal] =
    useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);

  const submitNewOrganizationName = async ({
    organizationId,
    organizationName,
  }) => {
    setErrorMessage(null);

    try {
      axios
        .put(
          `${VARIABLES.apiUrl}organization/${organizationId}/setname`,
          {
            organizationName,
          },
          {
            withCredentials: true,
          },
        )
        .then(() => {
          setHasSaved(true);
          setHasMadeChanges(false);
          dispatchNewOrganizationName({ newOrgName: organizationName });
        })
        .catch((error) => {
          reportError(error);
          setHasSaved(true);
          setErrorMessage("Error updating organization name");
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (organizationNameInit === organizationName) {
      setHasMadeChanges(false);
    }
  }, [organizationName]);

  useEffect(() => {
    if (hasSaved === true) {
      // Set a timer to clear the error message after 3 seconds
      const timer = setTimeout(() => {
        setHasSaved(false);
      }, 3000);

      // Cleanup function to clear the timer if errorMessage changes before 3 seconds
      return () => clearTimeout(timer);
    }
  }, [hasSaved]);

  useEffect(() => {
    if (errorMessage !== null) {
      // Set a timer to clear the error message after 3 seconds
      const timer = setTimeout(() => {
        setErrorMessage(null);
      }, 3000);

      // Cleanup function to clear the timer if errorMessage changes before 3 seconds
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  return (
    <UnividCard customPadding="calc(1em + 10px) 1em 1em calc(1em + 10px)">
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem
          onClick={() => {
            setShowDeleteOrganizationModal(true);
            setMenuAnchor(null);
          }}
        >
          <FaCircleXmark style={{ marginRight: "5px" }} /> Delete organization
        </MenuItem>
      </Menu>
      <h2 style={{ fontSize: "20px", marginBottom: "2vh" }}>
        General information
      </h2>

      <form
        className={styles.UpdateSessionContainer}
        onSubmit={(event) => {
          event.preventDefault();
          submitNewOrganizationName({
            organizationId,
            organizationName,
          });
        }}
      >
        <div
          className={styles.SubQuestionContainer}
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <div style={{ flex: "2", maxWidth: "700px" }}>
            <div className={styles.SectionSubTitleContainer}>
              <div className={styles.SectionSubTitle}>
                <div className={styles.SectionSubTitleText}>
                  Name (required)
                </div>
              </div>
            </div>
            <div
              className={styles.QuestionActiveContainer}
              style={{ padding: "10px 15px 0px 15px" }}
            >
              <RoundTextInput
                value={organizationName}
                onChange={(evt) => {
                  setHasMadeChanges(true);
                  setOrganizationName(evt.target.value);
                }}
                placeholder={"Organization name"}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    submitNewOrganizationName({
                      organizationId,
                      organizationName,
                    });
                  }
                }}
              />
            </div>
            <div className={styles.SectionSubTitleContainer}>
              <div className={styles.SectionSubTitle}>
                <div className={styles.SectionSubTitleText}>
                  Organization ID
                </div>
              </div>
            </div>
            <div
              className={styles.QuestionActiveContainer}
              style={{ padding: "10px 15px 0px 15px", color: "gray" }}
            >
              <RoundTextInput
                value={organizationId}
                placeholder={"Organization ID"}
                readOnly={true}
                style={{ color: "gray" }}
              />
            </div>
          </div>
        </div>
        <div className={styles.SubQuestionContainer}>
          <div className={styles.DoubleButtonContainer}>
            <UnividButton
              padding="fat"
              onClick={() => {
                submitNewOrganizationName({
                  organizationId,
                  organizationName,
                });
              }}
              disabled={!hasMadeChanges}
            >
              Update
            </UnividButton>
          </div>
        </div>

        {hasSaved == true && (
          <AlertMessageBox
            message={"Your new organization name is saved!"}
            isError={false}
          />
        )}
        {errorMessage != null && (
          <AlertMessageBox message={errorMessage} isError={true} />
        )}
      </form>
      <div
        style={{
          display: " flex",
          justifyContent: "flex-end",
          width: "100%",
          marginTop: "20px",
        }}
      >
        {numberOfOrganizationMembers > 1 || !currentUserIsAdmin ? (
          <HtmlToolTip
            title={
              <div>
                <p>
                  You cannot remove an organization with several members. Please
                  delete them first.
                </p>
              </div>
            }
          >
            <ExpandMenuButton
              className={styles.Card}
              disabled={numberOfOrganizationMembers > 1 || !currentUserIsAdmin}
              onClick={(e) => {
                setMenuAnchor(e.currentTarget);
              }}
            />
            <div></div>
          </HtmlToolTip>
        ) : (
          <ExpandMenuButton
            className={styles.Card}
            disabled={numberOfOrganizationMembers > 1 || !currentUserIsAdmin}
            onClick={(e) => {
              setMenuAnchor(e.currentTarget);
            }}
          />
        )}
      </div>
      {showDeleteOrganizationModal && (
        <RemoveOrganizationModal
          handleCloseModal={() => setShowDeleteOrganizationModal(false)}
          disabled={numberOfOrganizationMembers > 1 || !currentUserIsAdmin}
          organizationId={organizationId}
        />
      )}
    </UnividCard>
  );
};

export default EditOrganizationForm;
