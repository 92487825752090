import axios from "axios";
const retry = require("async-retry");

const MAX_RETRIES = 5;

const axiosRetry = async (
  { url, method, auth, withCredentials, headers, data },
  options,
) => {
  const maxRetries = options?.retries ?? MAX_RETRIES;
  if (!url || !method) {
    throw new Error("URL and method are required");
  }

  return retry(
    async (bail, attemptNumber) => {
      try {
        return await axios({
          url,
          method,
          auth,
          withCredentials,
          headers,
          data,
        });
      } catch (error) {
        const statusCode = error.response.status;

        if (statusCode === 503) {
          console.error({ error, url, method }, "Dropped, will retry.");
        }

        if (
          statusCode === 400 ||
          statusCode === 401 ||
          statusCode === 403 ||
          statusCode === 500
        ) {
          console.error(
            { error, url, method },
            "Unauthorized, will not retry.",
          );
          bail(new Error("Unauthorized"));
        }

        if (statusCode === 429) {
          if (attemptNumber === maxRetries) {
            console.error(
              { url, method },
              `Too many requests, attempt number ${attemptNumber} of ${maxRetries}. Will not retry.`,
            );
            bail(new Error("Reached maximum number of retries."));
          } else {
            console.error(
              { url, method },
              `Too many requests, attempt number ${attemptNumber}. Retrying...`,
            );
            throw new Error("Too many requests");
          }
        }
        throw error;
      }
    },
    {
      retries: maxRetries,
      minTimeout: 700,
      randomize: true,
      ...options,
    },
  );
};

export { axiosRetry };
